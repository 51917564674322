export default {
  defaultPath: "/charge_configuration/configure_charges",
  basename: "/solx", // only at build time to set, like ///mintone/react/default
  layout: "vertical", // vertical, horizontal
  collapseMenu: false, // mini-menu
  layoutType: "menu-light", // menu-dark, menu-light
  headerBackColor: "", // header-blue, header-red, header-purple, header-info, header-dark
  fullWidthLayout: true,
  navFixedLayout: true,
  headerFixedLayout: true,
  mainSiteUrl: "https://www.api.solx.ws",
};
