const DEMO = {
  BLANK_LINK: "#",
};

export default DEMO;

export const storage = {
  auth: "auth",
  refresh: "refresh",
};

export const logoAlt = "SOLX LOGO";

export const imageUrl = "https://www.api.solx.ws";

//  "http://localhost:3000"

export const siteUrl = "https://www.api.solx.ws";
export const zipCodes = [
  {
    village: "Aele",
    zip: "WS1382",
  },
  {
    village: "Afega",
    zip: "WS1385",
  },
  {
    village: "Alafua",
    zip: "WS1343",
  },
  {
    village: "Alamagoto",
    zip: "WS1331",
  },
  {
    village: "Aleisa",
    zip: "WS1362",
  },
  {
    village: "Amaile",
    zip: "WS1453",
  },
  {
    village: "Apolima Tai",
    zip: "WS1180",
  },
  {
    village: "Apolima Uta",
    zip: "WS1181",
  },
  {
    village: "Aufaga",
    zip: "WS1422",
  },
  {
    village: "Elise Fou",
    zip: "WS1351",
  },
  {
    village: "Eva",
    zip: "WS1481",
  },
  {
    village: "Faatoia",
    zip: "WS1323",
  },
  {
    village: "Fagalii",
    zip: "WS1311",
  },
  {
    village: "Fagaloa",
    zip: "WS1320",
  },
  {
    village: "Faleapuna",
    zip: "WS1473",
  },
  {
    village: "Faleasiu",
    zip: "WS1271",
  },
  {
    village: "Faleatiu",
    zip: "WS1292",
  },
  {
    village: "Falefa",
    zip: "WS1460",
  },
  {
    village: "Falelatai",
    zip: "WS1240",
  },
  {
    village: "Falelauniu",
    zip: "WS1361",
  },
  {
    village: "Faleolo",
    zip: "WS1290",
  },
  {
    village: "Faleseela",
    zip: "WS1251",
  },
  {
    village: "Fale'ula",
    zip: "WS1380",
  },
  {
    village: "Faleulu",
    zip: "WS1409",
  },
  {
    village: "Falevao",
    zip: "WS1461",
  },
  {
    village: "Fasitoo Tai",
    zip: "WS1280",
  },
  {
    village: "Fasitoo Uta",
    zip: "WS1270",
  },
  {
    village: "Fausaga",
    zip: "WS1302",
  },
  {
    village: "Fugalei",
    zip: "WS1337",
  },
  {
    village: "Fusi (Safata)",
    zip: "WS1304",
  },
  {
    village: "Fusi(AnoamaaSisifo)",
    zip: "WS1483",
  },
  {
    village: "Gagaifo le vao",
    zip: "WS1255",
  },
  {
    village: "Gaogao",
    zip: "WS1417",
  },
  {
    village: "Lalomanu",
    zip: "WS1431",
  },
  {
    village: "Lalomauga",
    zip: "WS1462",
  },
  {
    village: "Lata",
    zip: "WS1433",
  },
  {
    village: "Laulii",
    zip: "WS1313",
  },
  {
    village: "Leauvaa",
    zip: "WS1393",
  },
  {
    village: "Lefaga",
    zip: "WS1432",
  },
  {
    village: "Lepa",
    zip: "WS1421",
  },
  {
    village: "Lepea",
    zip: "WS1340",
  },
  {
    village: "Letogo",
    zip: "WS1312",
  },
  {
    village: "Leulumoega Tuai",
    zip: "WS1281",
  },
  {
    village: "Leusoalii",
    zip: "WS1491",
  },
  {
    village: "Lotofaga Safata",
    zip: "WS1307",
  },
  {
    village: "Lotofaga",
    zip: "WS1411",
  },
  {
    village: "Lotopa",
    zip: "WS1342",
  },
  {
    village: "Lotopue",
    zip: "WS1456",
  },
  {
    village: "Luatuanuu",
    zip: "WS1490",
  },
  {
    village: "Lufilufi",
    zip: "WS1471",
  },
  {
    village: "Maagiagi",
    zip: "WS1322",
  },
  {
    village: "Malaela",
    zip: "WS1440",
  },
  {
    village: "Malaemalu",
    zip: "WS1400",
  },
  {
    village: "Malie",
    zip: "WS1381",
  },
  {
    village: "Manono Tai",
    zip: "WS1190",
  },
  {
    village: "Manono Uta",
    zip: "WS1191",
  },
  {
    village: "Manunu",
    zip: "WS1474",
  },
  {
    village: "Matafaa",
    zip: "WS1252",
  },
  {
    village: "Matafele",
    zip: "WS1339",
  },
  {
    village: "Matatufu",
    zip: "WS1412",
  },
  {
    village: "Matautu(Aleipata)",
    zip: "WS1434",
  },
  {
    village: "Matautu (Falealili)",
    zip: "WS1413",
  },
  {
    village: "Matautu Lefaga",
    zip: "WS1260",
  },
  {
    village: "Matavai",
    zip: "WS1407",
  },
  {
    village: "Moamoa",
    zip: "WS1335",
  },
  {
    village: "Motootua",
    zip: "WS1330",
  },
  {
    village: "Mulifanua",
    zip: "WS1170",
  },
  {
    village: "Mulinuu",
    zip: "WS1338",
  },
  {
    village: "Mulivai",
    zip: "WS1300",
  },
  {
    village: "Mutiatele",
    zip: "WS1455",
  },
  {
    village: "Nofoalii",
    zip: "WS1282",
  },
  {
    village: "Nuu",
    zip: "WS1383",
  },
  {
    village: "Nuusuatia",
    zip: "WS1306",
  },
  {
    village: "Papauta",
    zip: "WS1333",
  },
  {
    village: "Poutasi",
    zip: "WS1415",
  },
  {
    village: "Puipaa",
    zip: "WS1370",
  },
  {
    village: "Saanapu",
    zip: "WS1308",
  },
  {
    village: "Safaatoa",
    zip: "WS1250",
  },
  {
    village: "Saina",
    zip: "WS1372",
  },
  {
    village: "Salamumu",
    zip: "WS1309",
  },
  {
    village: "Salani",
    zip: "WS1403",
  },
  {
    village: "Saleapaga",
    zip: "WS1420",
  },
  {
    village: "Saleaumua",
    zip: "WS1450",
  },
  {
    village: "Saleilua",
    zip: "WS1416",
  },
  {
    village: "Saleimoa",
    zip: "WS1390",
  },
  {
    village: "Salelesi",
    zip: "WS1482",
  },
  {
    village: "Salesatele",
    zip: "WS1405",
  },
  {
    village: "Samatau",
    zip: "WS1230",
  },
  {
    village: "Samusu",
    zip: "WS1452",
  },
  {
    village: "Saoluafata",
    zip: "WS1470",
  },
  {
    village: "Sapoe",
    zip: "WS1402",
  },
  {
    village: "Sapunaoa",
    zip: "WS1404",
  },
  {
    village: "Satalo",
    zip: "WS1408",
  },
  {
    village: "Sataoa",
    zip: "WS1305",
  },
  {
    village: "Satapuala",
    zip: "WS1291",
  },
  {
    village: "Satitoa",
    zip: "WS1441",
  },
  {
    village: "Satui",
    zip: "WS1182",
  },
  {
    village: "Satuimalufilufi",
    zip: "WS1171",
  },
  {
    village: "Sauniatu",
    zip: "WS1472",
  },
  {
    village: "Savaia",
    zip: "WS1261",
  },
  {
    village: "Seesee",
    zip: "WS1344",
  },
  {
    village: "Sinamoga",
    zip: "WS1336",
  },
  {
    village: "Siufaga",
    zip: "WS1424",
  },
  {
    village: "Siumu",
    zip: "WS1314",
  },
  {
    village: "Siusega",
    zip: "WS1360",
  },
  {
    village: "Solosolo",
    zip: "WS1480",
  },
  {
    village: "Tafaigata",
    zip: "WS1363",
  },
  {
    village: "Tafatafa",
    zip: "WS1406",
  },
  {
    village: "Tafitoala",
    zip: "WS1301",
  },
  {
    village: "Tagamanu",
    zip: "WS1253",
  },
  {
    village: "Talimatau",
    zip: "WS1354",
  },
  {
    village: "Tiavea",
    zip: "WS1451",
  },
  {
    village: "Toamua",
    zip: "WS1371",
  },
  {
    village: "Tuanai",
    zip: "WS1384",
  },
  {
    village: "Tuanaimato",
    zip: "WS1345",
  },
  {
    village: "Tufulele",
    zip: "WS1391",
  },
  {
    village: "Tulaele",
    zip: "WS1353",
  },
  {
    village: "Ulutogia",
    zip: "WS1442",
  },
  {
    village: "Utualii",
    zip: "WS1392",
  },
  {
    village: "Utufaalalafa",
    zip: "WS1454",
  },
  {
    village: "Utulaelae",
    zip: "WS1401",
  },
  {
    village: "Vaiala",
    zip: "WS1332",
  },
  {
    village: "Vaiama",
    zip: "WS1254",
  },
  {
    village: "Vaiee",
    zip: "WS1303",
  },
  {
    village: "Vaigaga",
    zip: "WS1365",
  },
  {
    village: "Vaigalu",
    zip: "WS1423",
  },
  {
    village: "Vailele",
    zip: "WS1310",
  },
  {
    village: "Vailoa (Faleata)",
    zip: "WS1350",
  },
  {
    village: "Vailoa(Aleipata)",
    zip: "WS1430",
  },
  {
    village: "Vailuutai",
    zip: "WS1293",
  },
  {
    village: "Vaimoso",
    zip: "WS1334",
  },
  {
    village: "Vaitele",
    zip: "WS1364",
  },
  {
    village: "Vaitoloa",
    zip: "WS1341",
  },
  {
    village: "Vaiula",
    zip: "WS1418",
  },
  {
    village: "Vaiusu",
    zip: "WS1352",
  },
  {
    village: "Vaivase",
    zip: "WS1321",
  },
  {
    village: "Vaovai",
    zip: "WS1414",
  },
  {
    village: "Vavau",
    zip: "WS1410",
  },
  {
    village: "Alelai",
    zip: "WS2484",
  },
  {
    village: "Aopo",
    zip: "WS2490",
  },
  {
    village: "Asaga",
    zip: "WS2173",
  },
  {
    village: "Asau",
    zip: "WS2491",
  },
  {
    village: "Auala",
    zip: "WS2492",
  },
  {
    village: "Autu",
    zip: "WS2483",
  },
  {
    village: "Avao",
    zip: "WS2383",
  },
  {
    village: "Faga",
    zip: "WS2170",
  },
  {
    village: "Fagafau",
    zip: "WS2590",
  },
  {
    village: "Fagamalo",
    zip: "WS2381",
  },
  {
    village: "Fagasa",
    zip: "WS2481",
  },
  {
    village: "Faiaai",
    zip: "WS2593",
  },
  {
    village: "Falealupo",
    zip: "WS2495",
  },
  {
    village: "Falelima",
    zip: "WS2498",
  },
  {
    village: "Faletagaloa",
    zip: "WS2295",
  },
  {
    village: "Fatuvalu",
    zip: "WS2293",
  },
  {
    village: "Foalalo",
    zip: "WS2671",
  },
  {
    village: "Foaluga",
    zip: "WS2672",
  },
  {
    village: "Fogapoa",
    zip: "WS2182",
  },
  {
    village: "Fogasavaii",
    zip: "WS2594",
  },
  {
    village: "Fogatuli",
    zip: "WS2592",
  },
  {
    village: "Fusi",
    zip: "WS2183",
  },
  {
    village: "Gataivai",
    zip: "WS2584",
  },
  {
    village: "Gautavai",
    zip: "WS2580",
  },
  {
    village: "Iva",
    zip: "WS2193",
  },
  {
    village: "Lalomalava",
    zip: "WS2192",
  },
  {
    village: "Lano",
    zip: "WS2174",
  },
  {
    village: "Leafagoalii",
    zip: "WS2291",
  },
  {
    village: "Leagiagi",
    zip: "WS2372",
  },
  {
    village: "Lelepa",
    zip: "WS2382",
  },
  {
    village: "Letui",
    zip: "WS2389",
  },
  {
    village: "Lolua",
    zip: "WS2374",
  },
  {
    village: "Luua",
    zip: "WS2188",
  },
  {
    village: "Malae",
    zip: "WS2189",
  },
  {
    village: "Manase",
    zip: "WS2385",
  },
  {
    village: "Maota",
    zip: "WS2199",
  },
  {
    village: "Matavai Safune",
    zip: "WS2294",
  },
  {
    village: "Matavai Vaisigano 1",
    zip: "WS2480",
  },
  {
    village: "Mauga",
    zip: "WS2298",
  },
  {
    village: "Neiafu",
    zip: "WS2497",
  },
  {
    village: "Paia",
    zip: "WS2292",
  },
  {
    village: "Papa",
    zip: "WS2482",
  },
  {
    village: "Patamea",
    zip: "WS2297",
  },
  {
    village: "Puapua",
    zip: "WS2172",
  },
  {
    village: "Puleia",
    zip: "WS2581",
  },
  {
    village: "Saasaai",
    zip: "WS2184",
  },
  {
    village: "Safai",
    zip: "WS2370",
  },
  {
    village: "Safotu",
    zip: "WS2387",
  },
  {
    village: "Safotulafai",
    zip: "WS2180",
  },
  {
    village: "Safua",
    zip: "WS2196",
  },
  {
    village: "Safune",
    zip: "WS2386",
  },
  {
    village: "Sagone",
    zip: "WS2595",
  },
  {
    village: "Saipipi",
    zip: "WS2171",
  },
  {
    village: "Salailua",
    zip: "WS2674",
  },
  {
    village: "Saleaula",
    zip: "WS2299",
  },
  {
    village: "Saleia",
    zip: "WS2384",
  },
  {
    village: "Salelavalu",
    zip: "WS2191",
  },
  {
    village: "Salelologa",
    zip: "WS2190",
  },
  {
    village: "Saletele",
    zip: "WS2373",
  },
  {
    village: "Samalaeulu",
    zip: "WS2296",
  },
  {
    village: "Samata",
    zip: "WS2591",
  },
  {
    village: "Samauga",
    zip: "WS2290",
  },
  {
    village: "Sapapalii",
    zip: "WS2181",
  },
  {
    village: "Sapini",
    zip: "WS2187",
  },
  {
    village: "Sasina",
    zip: "WS2388",
  },
  {
    village: "Sataua",
    zip: "WS2494",
  },
  {
    village: "Satoalepai",
    zip: "WS2380",
  },
  {
    village: "Satuiatua",
    zip: "WS2673",
  },
  {
    village: "Satupaitea",
    zip: "WS2583",
  },
  {
    village: "Sili",
    zip: "WS2582",
  },
  {
    village: "Siufaga",
    zip: "WS2186",
  },
  {
    village: "Siufaga",
    zip: "WS2375",
  },
  {
    village: "Siutu",
    zip: "WS2676",
  },
  {
    village: "Siuvao",
    zip: "WS2485",
  },
  {
    village: "Tafua",
    zip: "WS2198",
  },
  {
    village: "Taga",
    zip: "WS2670",
  },
  {
    village: "Taga",
    zip: "WS2675",
  },
  {
    village: "Tufutafoe",
    zip: "WS2496",
  },
  {
    village: "Utuloa",
    zip: "WS2499",
  },
  {
    village: "Vaiafai",
    zip: "WS2194",
  },
  {
    village: "Vaimauga",
    zip: "WS2185",
  },
  {
    village: "Vaipouli",
    zip: "WS2371",
  },
  {
    village: "Vaipua",
    zip: "WS2596",
  },
  {
    village: "Vaisala",
    zip: "WS2493",
  },
  {
    village: "Vaisaulu",
    zip: "WS2195",
  },
  {
    village: "Vao-i-Iva",
    zip: "WS2197",
  },
];


export const basePath="https://www.api.solx.ws";